import type { FC } from 'react';
import React, { useCallback, useContext } from 'react';

// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';
import { Coordination } from '@atlassiansox/engagekit-ts';
import type { CoordinationClientType } from '@atlassiansox/engagekit-ts';

import { BannerStateContainer } from '@confluence/banners';
import type { UpFlowContextType } from '@confluence/change-edition';
import { ConfluenceEdition, UpFlowContext } from '@confluence/change-edition';
import { useCoordinationClient } from '@confluence/engagement-provider';
import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';

import {
	resetPreTrialCadence,
	isNextPreTrialCadence,
	setNextPreTrialCadence,
} from './computePersistentUpgradeBannerCadence';
import { PreTrialBanner, BANNER_NAME } from './PreTrialBanner';
import { usePersistentUpgradeButton } from './PersistentUpgradeButton/usePersistentUpgradeButton';
import { useUpflowModal } from './useUpflowModal';

// go/persistent-upgrade
export const PERSISTENT_BANNER_EP_MESSAGE_ID = 'upflow-confluence-persistent-banner';

// Session storage key for forcing Engagement Platform messages to always be shown
export const COORDINATION_OVERRIDE_SESSION_FLAG = 'confluence.override-coordination-for-tests';

// Engagement Platform messages are disabled for integration tests because we usually don't want to
// have marketing or onboarding messages popping up at random times that either change the UI
// and/or require the user to do something to clear them.  However, we do want to validate that
// Upflow's Persistent Upgrade Banner is being shown at the right times/places.  So this extra
// wrapper will effectively override the Coordination client for integration tests for the
// Persistent Upgrade Baner only when the session flag is set to "true" (the default is no flag
// value, so effectively false for most tests).
const TestableCoordinationWrapper: FC<{
	client: CoordinationClientType;
	messageId: string;
	children?: React.ReactNode;
}> = ({ children, client, messageId }) =>
	sessionStorage.getItem(COORDINATION_OVERRIDE_SESSION_FLAG) !== 'true' ? (
		<Coordination client={client} messageId={messageId}>
			<>{children}</>
		</Coordination>
	) : (
		<>{children}</>
	);

export type CoordinationClientProps = {
	coordinationClient: CoordinationClientType;
};

export const PersistentUpgradeBannerComponent: FC = () => {
	const { currentEdition } = useContext<UpFlowContextType>(UpFlowContext);
	const coordinationClient = useCoordinationClient();

	// Callback to handle banner dismissed
	const onPreTrialBannerDismiss = useCallback(() => {
		// The banner has been dismissed by the user. setNextPreTrialCadence will
		// compute the next banner message and banner display time.
		setNextPreTrialCadence();
	}, []);

	if (currentEdition !== ConfluenceEdition.FREE) {
		// Since we don't show banners for Standard/Premium edition, we should
		// reset/cleanup the existing pre-trial cadence data and return.
		resetPreTrialCadence();
		return null;
	}

	if (!isNextPreTrialCadence()) {
		// The user is targeted for this feature but the banner was dismissed and
		// the next cadence condition is not satisfied.
		return null;
	}

	// The EP message Expiry is set to 0 - the number of minutes the message
	// can be active before it's expired; it releases the lock as soon as it can start.
	return (
		<TestableCoordinationWrapper
			client={coordinationClient}
			messageId={PERSISTENT_BANNER_EP_MESSAGE_ID}
		>
			<PreTrialBanner onBannerDismiss={onPreTrialBannerDismiss} />
		</TestableCoordinationWrapper>
	);
};

// Wrapper component to ensure that we only render PersistentUpgradeBannerComponent
// when certain conditions are satisfied (e.g. is site admin).
export const PersistentUpgradeBannerWrapper: FC = () => {
	// Get Upflow context data
	const { cloudId, currentEdition, isSiteAdmin } = useContext<UpFlowContextType>(UpFlowContext);

	const { UpflowModalMountPoint } = useUpflowModal();

	// This feature targets site admin users.
	if (!isSiteAdmin || !currentEdition || !cloudId) {
		return null;
	}

	return (
		<>
			<PersistentUpgradeBannerComponent />
			<UpflowModalMountPoint />
		</>
	);
};

export const PersistentUpgradeBannerStateContainer: FC = () => {
	const { isPersistentUpgradeButtonEligible } = usePersistentUpgradeButton();

	const { UpflowModalMountPoint } = useUpflowModal();

	const shouldShowBanner = !isPersistentUpgradeButtonEligible;

	return shouldShowBanner ? (
		<Subscribe to={[BannerStateContainer]}>
			{(bannerState: BannerStateContainer) => {
				const hasOtherBanners = bannerState?.state?.banners?.some(
					(banner) => banner.name !== BANNER_NAME,
				);

				// Prevents the PU banner from clashing with Storage Enforcement banner
				if (hasOtherBanners) {
					return null;
				}

				return <PersistentUpgradeBannerWrapper />;
			}}
		</Subscribe>
	) : (
		<UpflowModalMountPoint />
	);
};

export const PersistentUpgradeBanner = withTransparentErrorBoundary({
	attribution: Attribution.GROWTH_LUNA,
})(PersistentUpgradeBannerStateContainer);
