import type { FC } from 'react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { BannerStateContainer } from '@confluence/banners';
import type { UpFlowContextType } from '@confluence/change-edition';
import { UpFlowContext } from '@confluence/change-edition';
import { gridSize } from '@confluence/typography-placeholder';
import { DismissibleBanner } from '@confluence/banners/entry-points/DismissibleBanner';

import { PERSISTENT_UPGRADE_FLOWID, PERSISTENT_UPGRADE_TOUCHPOINT_BANNER } from './constants';
import { constructUIEvent } from './constructPersistentUpgradeAnalyticsEvents';
import {
	getCurrentPreTrialCadence,
	PERSISTENT_PRETRIAL_BANNERS,
} from './computePersistentUpgradeBannerCadence';
import { PreTrialBannerMessage } from './PreTrialBannerMessage';

const BANNER_HEIGHT = gridSize * 4; // 32px when gridSize = 8px
export const BANNER_NAME = 'PersistentUpgradeBanner';

export type PreTrialBannerProps = {
	onBannerDismiss: Function;
};

export const PreTrialBanner: FC<PreTrialBannerProps> = (props) => {
	const [isBannerClosed, setIsBannerClosed] = useState<boolean>(false);

	return isBannerClosed ? null : (
		<Subscribe to={[BannerStateContainer]}>
			{(bannerState: BannerStateContainer) => {
				bannerState.show(BANNER_NAME, BANNER_HEIGHT);

				return (
					<PreTrialBannerComponent
						{...props}
						bannerState={bannerState}
						setIsBannerClosed={setIsBannerClosed}
					/>
				);
			}}
		</Subscribe>
	);
};

type PreTrialBannerComponentProps = PreTrialBannerProps & {
	bannerState: BannerStateContainer;
	setIsBannerClosed: (isBannerClosed: boolean) => void;
};

const PreTrialBannerComponent: FC<PreTrialBannerComponentProps> = ({
	bannerState,
	onBannerDismiss,
	setIsBannerClosed,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { currentEdition } = useContext<UpFlowContextType>(UpFlowContext);

	const currentCadence = getCurrentPreTrialCadence();
	const touchpointId = PERSISTENT_PRETRIAL_BANNERS[currentCadence];

	const fireBannerUIEvent = useCallback(
		(eventAction: string) => {
			createAnalyticsEvent(
				constructUIEvent({
					eventAction,
					touchpointSubject: PERSISTENT_UPGRADE_TOUCHPOINT_BANNER,
					currentEdition,
					touchpointId,
					flowId: PERSISTENT_UPGRADE_FLOWID,
				}),
			).fire();
		},
		[createAnalyticsEvent, currentEdition, touchpointId],
	);

	const onView = useCallback(() => {
		fireBannerUIEvent('viewed');
	}, [fireBannerUIEvent]);

	useEffect(() => () => bannerState.hide(BANNER_NAME), [bannerState]);

	return (
		<DismissibleBanner
			height={BANNER_HEIGHT}
			onView={onView}
			onDismiss={() => {
				setIsBannerClosed(true);
				onBannerDismiss();
				fireBannerUIEvent('dismissed');
			}}
		>
			<PreTrialBannerMessage cadenceIndex={currentCadence} />
		</DismissibleBanner>
	);
};
