import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';

import { PERSISTENT_UPGRADE_EDITION_TRIAL_TYPES } from './constants';

// (86,400 seconds in a day * 1000)
const DAY_IN_MS = 86400000;

// 0 days, 7 days, 21 days, 42 days
const PERSISTENT_PRETRIAL_BANNER_CADENCES_MS = [0, 7 * DAY_IN_MS, 21 * DAY_IN_MS, 42 * DAY_IN_MS];

export const EditionTrialType2LocalStorageKeyMap: {
	[index: string]: string;
} = {
	[PERSISTENT_UPGRADE_EDITION_TRIAL_TYPES.PRE_TRIAL]:
		PERSISTED_KEYS_ON_SERVER.PERSISTED_GROWTH_PERSISTENT_UPGRADE_PRETRIAL_BANNER,
};

export const PERSISTENT_PRETRIAL_BANNERS = ['preTrial-0', 'preTrial-1', 'preTrial-2', 'preTrial-3'];

// Determine the current pre-trial banner cadence from storage. This function assumes that isNextPreTrialCadence() returned true.
export const getCurrentPreTrialCadence = (): number => {
	const preTrialCadenceKey =
		EditionTrialType2LocalStorageKeyMap[PERSISTENT_UPGRADE_EDITION_TRIAL_TYPES.PRE_TRIAL];
	try {
		const jsonString = localStorage.getItem(preTrialCadenceKey);
		if (jsonString) {
			const { cadence } = JSON.parse(jsonString);
			if (
				typeof cadence === 'number' &&
				cadence >= 0 &&
				cadence < PERSISTENT_PRETRIAL_BANNERS.length
			) {
				// For pre-trial, return local storage cadence
				return cadence;
			}
		}
	} catch {}

	return 0;
};

// Determine whether the conditions for showing the next pre-trial banner cadence have been met. This function
// is expected to be called prior to rendering the pre-trial banner.
export const isNextPreTrialCadence = (): boolean => {
	try {
		const preTrialCadenceKey =
			EditionTrialType2LocalStorageKeyMap[PERSISTENT_UPGRADE_EDITION_TRIAL_TYPES.PRE_TRIAL];
		if (preTrialCadenceKey) {
			if (!localStorage.doesContain(preTrialCadenceKey)) {
				return true;
			} else {
				const { showAfter } = JSON.parse(localStorage.getItem(preTrialCadenceKey));
				return showAfter <= Date.now();
			}
		}
	} catch {}
	return false;
};

// Compute and save the next pre-trial banner cadence. This function is expected to be called after pre-trial banner dismissal.
export const setNextPreTrialCadence = (): void => {
	const preTrialCadenceKey =
		EditionTrialType2LocalStorageKeyMap[PERSISTENT_UPGRADE_EDITION_TRIAL_TYPES.PRE_TRIAL];
	if (preTrialCadenceKey) {
		try {
			const currentPreTrialCadence = getCurrentPreTrialCadence();

			// For pre-trial, the cadence should stick to the last valid cadence (e.g. 42 days after the last banner dismiss)
			const newCadence = Math.min(
				currentPreTrialCadence + 1,
				PERSISTENT_PRETRIAL_BANNERS.length - 1,
			);

			const newLocalStorageValue = {
				cadence: newCadence,
				showAfter: Date.now() + PERSISTENT_PRETRIAL_BANNER_CADENCES_MS[newCadence],
			};

			localStorage.setItem(preTrialCadenceKey, JSON.stringify(newLocalStorageValue));
		} catch {}
	}
};

// Clear pre-trial cadence. If the user upgrades from free to standard and then downgrades back to free, we restart the pre-trial cadence.
export const resetPreTrialCadence = (): void => {
	const preTrialCadenceKey =
		EditionTrialType2LocalStorageKeyMap[PERSISTENT_UPGRADE_EDITION_TRIAL_TYPES.PRE_TRIAL];
	if (preTrialCadenceKey) {
		localStorage.removeItem(preTrialCadenceKey);
	}
};
